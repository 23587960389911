import React from 'react';

import * as OrderType         from 'shared/types/Order';
import * as OrdersPayloadType from 'shared/types/OrdersPayload';
import * as images            from 'images/';
import tectransit             from 'utils/TecTransit';
import getApiPromise          from 'utils/getApiPromise';
import { Position }           from 'utils/MapMarker';
import PlacesAutocomplete     from 'components/PlacesAutocomplete';

export interface Props {
    onChoose : ( pos:Position ) => void;
    onCancel : () => void;
};

export default class PositionSelector extends React.Component {

    private static getEventKey = (e:OrderType.Event) => {
        // Dates come as strings here because it came over web from /api/passenger/orders
        return String(e.at||e.earliest_eta||e.address||'?');
    };
    private knownAddressesDom   : any;
    // @ts-expect-error TS2564
    public  props               : Props;
    public  state               : {
        autocompleteValue : string,
        events            : OrderType.Event[]
    };

    constructor( props:Props ) {
        super(props);
        this.state = {
            autocompleteValue : '',
            events            : []
        };
        this.knownAddressesDom = this.getKnownAddressesDom();

    }
    private getKnownAddressesDom() {
        const specialAddresses = {
            Home : {
                ndx      : 3,
                fa_style : 'fa-home'
            },
            Work : {
                ndx      : 2,
                fa_style : 'fa-briefcase'
            }
        } as Record<string,any>;
        const specialsFirstSorter   = ( a1:string, a2:string ) => {
            const a1_ndx = specialAddresses[a1]?.ndx;
            const a2_ndx = specialAddresses[a2]?.ndx;
            return (a1_ndx || a2_ndx) ? (a2_ndx||0)-(a1_ndx||0) : a1.localeCompare(a2);
        };
        const knownAddresses = tectransit.user.addresses || {};
        return Object.keys(knownAddresses).sort(specialsFirstSorter).map( (name) => {
            const buttonProps = {
                key       : `address_${name}`,
                className : "btn btn-outline-success col mx-2",
                onClick   : ()=>this.props.onChoose(knownAddresses[name])
            };
            return (<button {...buttonProps}>
                <i className={`fa ${(specialAddresses[name]||{}).fa_style||'fa-map-pin'}`} style={{width:"16px",height:"20px"}}></i>
                   &nbsp;<span title={knownAddresses[name].address}>{name}</span>
            </button>);
        });
    }
    private fetchOrders() {
        return getApiPromise<OrdersPayloadType.Dehydrated<number>>('/api/passenger/orders','GET',undefined,{limit:'20',orderby:'created_at desc'})
            .then( ordersPayload => {
                const orders    = OrdersPayloadType.hydrate(ordersPayload);
                const rawEvents = (orders||[]).map(o=>[o.pickup,o.dropoff]).flat().reduce( (acc,event) => {
                    if( event.address )
                        acc[event.address] = event;
                    return acc;
                },{} as Record<string,OrderType.Event>);
                const events    = Object.keys(rawEvents)
                    .sort((p1,p2)=>PositionSelector.getEventKey(rawEvents[p2]).localeCompare(PositionSelector.getEventKey(rawEvents[p1])))
                    .map(k=>rawEvents[k]);
                return this.setState({events});
            });
    }
    private keyhandle = (event:any) => {
        if( event.key==='Escape' ) {
            event.preventDefault();
            event.stopPropagation();
            this.props.onCancel();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown",this.keyhandle);
        this.fetchOrders();
    }
    componentWillUnmount() {
        document.removeEventListener("keydown",this.keyhandle);
    }
    render() {
        return (
            <div className="wrapper">
                <div className="section-card section-selector">
                    <PlacesAutocomplete
                        inputProps = {{
                            value     : this.state.autocompleteValue,
                            className : "input-theme"
                        }}
                        onSelect  = {(pp:google.maps.places.PlacePrediction) => {
                            tectransit.geocoder.getPlaceidLookupPromise(pp.placeId).then( geocoderResult => {
                                this.props.onChoose({
                                    address  : geocoderResult.formatted_address,
                                    location : geocoderResult.geometry.location.toJSON()
                                });
                            });
                        }}
                    />
                    {
                        (this.knownAddressesDom||[]).length ? (<div className="addressPrediction">{this.knownAddressesDom}</div>) : null
                    }
                    {
                        (this.state.events||[]).map( e =>(
                            <div className="addressPrediction__item" key={PositionSelector.getEventKey(e)} onClick={()=>this.props.onChoose(e as Position)}>
                                <div className="addressPrediction__icon">
                                    <images.PastOrder/>
                                </div>
                                <div className="addressPrediction__name" style={{cursor:'pointer'}}>{e.address}</div>
                            </div>
                        ))
                    }
                    <div className="addressPrediction__btn" onClick={()=>this.props.onCancel()}>
                        <button type="button" className="btn-theme" >
                            <images.BackToMap/>
                            Back to Map
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

