import redux                from "redux";

import User                 from '../shared/types/User';
import Agency               from '../shared/types/Agency';
import Menu                 from './Menu';

export const AGENCY__UPDATE         = "AGENCY__UPDATE";
export const USER__UPDATE           = "USER__UPDATE";
export const MENU__UPDATE           = "MENU__UPDATE";

export interface State {
    user        : User;
    agency      : Agency;
    menu?       : Menu;
};

export interface Action extends redux.Action<string> {
    payload : any;
}

export const userReducer = ( user:User, action:Action ) : User => {
    const get_new_state = () => {
        switch( action.type ) {
        case USER__UPDATE:
            return action.payload as User;
        default:
            return {};
        }
    };
    return {
        ...user,
        ...get_new_state()
    };
}

export const agencyReducer = ( agency:Agency, action:Action ) : Agency => {
    const get_new_state = () => {
        switch( action.type ) {
        case AGENCY__UPDATE: {
            return action.payload;
        }
        default:
            return {};
        }
    };
    return {
        ...agency,
        ...get_new_state()
    };
}

export const menuReducer = ( menu:Menu, action:Action ) : Menu => {
    const get_new_state = () => {
        switch( action.type ) {
        case MENU__UPDATE: {
            return action.payload;
        }
        default:
            return {};
        }
    };
    return {
        ...menu,
        ...get_new_state()
    };
}

