import React                                    from 'react';
import jsonParse                                from 'shared/utils/jsonParse';

import * as ApcType                             from 'utils/APC';
import * as TecTransITPluginTypes               from 'plugins/TecTransIT/types';
import * as TecTransITPlugin                    from 'plugins/TecTransIT';

import * as MenuItem                            from 'components/MenuItem';
import { Capacitor }                            from '@capacitor/core';
import { Device,DeviceInfo }                    from '@capacitor/device';

interface Props {
}
interface State {
    pluginBuildConfig?           : Record<string,any>;
    openTcpPortResult?           : TecTransITPluginTypes.OpenTcpPortResult;
    apcMessage?                  : string;
    deviceInfo?                  : DeviceInfo;
}
export default class PluginTest extends React.Component<Props,State> {

    // Used by above components
    public state : State = {
    };

    // public
    componentDidMount() {
        // Let's start listening for APC device messages
        const onApcData = ( data:any ) => {
            const packet = jsonParse(data,{}) as ApcType.Packet;
            if( !packet.sn || !packet.type )
                throw Error(`Got wrong data from APC: ${data}`);
            console.log("Got packet",data);
        };
        TecTransITPlugin.openTcpPort({port:ApcType.tcpPort,onEvent:onApcData})
            .then( openTcpPortResult => {
                console.log(`Opened TCP port ${ApcType.tcpPort}`,JSON.stringify(openTcpPortResult));
                this.setState({openTcpPortResult});
            })
            .catch( err => {
                const lastReload = Number(sessionStorage.getItem('lastCapacitorHackingReload'));
                if( (Date.now()-lastReload)>(1000*60*5) ) {
                    // If we've reloaded the page in the last 5 minutes, don't keep reloading
                    // Just report an error
                    sessionStorage.setItem('lastCapacitorHackingReload',String(Date.now()));
                    window.location.reload();
                }
                else {
                    this.setState({
                        apcMessage : `Cannot open TCP port ${ApcType.tcpPort} ${err.message}`
                    });
                }
            });
        TecTransITPlugin.buildConfig()
            .then( pluginBuildConfig => {
                console.log('buildConfig',JSON.stringify(pluginBuildConfig));
                this.setState({pluginBuildConfig});
            })
            .catch( err => {
                this.setState({
                    apcMessage : `Cannot get build config ${err.message}`
                });
            });
        Device.getInfo()
            .then( deviceInfo => {
                console.log('Device.getInfo',JSON.stringify(deviceInfo));
                this.setState({deviceInfo});
            })
            .catch( err => {
                this.setState({
                    apcMessage : `Cannot get device info ${err.message}`
                });
            });
    }
    componentWillUnmount() {
        if( this.state.openTcpPortResult ) {
            TecTransITPlugin.closeTcpPort(this.state.openTcpPortResult)
                .then( res => {
                    console.log(`Closed TCP port ${ApcType.tcpPort}`,res);
                })
                .catch( err => {
                    console.error(`Cannot close TCP port ${ApcType.tcpPort}`,err);
                });
        }
    }
    render() {
        return (
            <MenuItem.MenuItem title="PluginTest">
                {MenuItem.withAlert((alert) => {
                    return (<table>
                        <tbody>
                        <tr>
                            <td>APC Message</td>
                            <td>{JSON.stringify(this.state.apcMessage||'n/a')}</td>
                        </tr>
                        <tr>
                            <td>openTcpPortResult</td>
                            <td>{JSON.stringify(this.state.openTcpPortResult||{})}</td>
                        </tr>
                        <tr>
                            <td>buildConfig</td>
                            <td>{JSON.stringify(this.state.pluginBuildConfig||{})}</td>
                        </tr>
                        <tr>
                            <td>DeviceInfo</td>
                            <td>{JSON.stringify(this.state.deviceInfo)}</td>
                        </tr>
                        <tr>
                            <td>Capacitor.getPlatform</td>
                            <td>{JSON.stringify(Capacitor.getPlatform())}</td>
                        </tr>
                    </tbody>
                    </table>);
                })}
            </MenuItem.MenuItem>
        );
    }
}
