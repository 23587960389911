import React              from 'react';

import * as AgencyType    from 'shared/types/Agency';
import dayjs              from 'shared/utils/day-timezone';

import Alert              from 'utils/Alert';
import tectransit         from 'utils/TecTransit';
import getApiPromise      from 'utils/getApiPromise';
import englishWeekdays    from 'utils/englishWeekdays';
import * as MenuItem      from 'components/MenuItem';

export default function Hours() {

    let alert = new Alert();
    const [workminutes,setWorkminutes] = React.useState<AgencyType.NumRange[][]>(tectransit.agency.workminutes);
    const [holidays, setHolidays]      = React.useState<Record<string,string>>(tectransit.agency.holidays);

    const getHoursElements = () => {
        const getWorkminutes = () : AgencyType.NumRange[][] => {
            return Array(7).fill(0).map((n, ndx) => {
                const result = [] as AgencyType.NumRange[];
                let range_start = undefined as (HTMLOptionElement | undefined);
                let prev_option = undefined as (HTMLOptionElement | undefined);
                // @ts-expect-error
                Array.apply(null, (document.getElementById(`weekday_${ndx}`) as HTMLSelectElement).options).forEach((o: HTMLOptionElement) => {
                    if (o.selected) {
                        if (!range_start)
                            range_start = o; // open a new range
                    }
                    else {
                        if (range_start)
                            result.push([Number(range_start.value), Number(prev_option!.value)]); // finish the range
                        range_start = undefined;
                    }
                    prev_option = o;
                });
                if (range_start) {
                    // This means that the last option '23:45' has been selected. We need to "close" it with "24:00"
                    result.push([Number(range_start.value), 24 * 60]);
                }
                return result;
            });
        };
        const toHoursAndMins = (mins: number): string => {
            const hours = String(Math.floor(mins / 60));
            const mins_ = String(mins % 60);
            return `${hours.padStart(2, "0")}:${mins_.padStart(2, "0")}`;
        };
        const getWorkminutesDescription = (wh: AgencyType.NumRange[]) => {
            if ((wh || []).length < 1)
                return `closed`;
            return wh.map(range => `${toHoursAndMins(range[0])} to ${toHoursAndMins(range[1])}`).join(", ");
        };
        const isInRange = (ranges: number[][], mins: number): boolean => {
            const result = (ranges || []).find(r => {
                return (r[0] <= mins) && (mins <= r[1]);
            }) !== undefined;
            return result;
        };
        const every15mins = Array((24 * 60) / 15).fill(0).map((o, ndx) => String(15 * ndx));
        return (
            <div className="section-card top-1">
                <div className="section-card__header">
                    <div className="section-card__title">Hours</div>
                    <span>
                        Set the daily operating hours of your Agency below. The times are in the agency time zone ({tectransit.agency.time_zone})
                    </span>
                </div>
                <div className="hours-inner top-1">
                    {englishWeekdays.map((wd, ndx) => (
                        <div key={ndx} className="hour-row">
                            <div key={`wd_${ndx}`} className="hours-item">
                                <div className="hours-block">
                                    <span>{wd} ({getWorkminutesDescription(workminutes[ndx])})</span>
                                    <select
                                        multiple
                                        id={`weekday_${ndx}`}
                                        onChange={e => setWorkminutes(getWorkminutes())}
                                        defaultValue={every15mins.filter(mins => isInRange(workminutes[ndx], Number(mins)))}
                                    >
                                        {every15mins.map(mins => <option key={mins} value={mins}>{toHoursAndMins(Number(mins))}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    const getHolidaysElements = () => {
        return (
            <div className="section-card top-1">
                <div className="section-card__header">
                    <div className="section-card__title">Holidays</div>
                    <span>
                        Set the agency holidays.
                        The dates are in the agency time zone ({tectransit.agency.time_zone})
                    </span>
                </div>
                <div className="hours-inner top-1">
                    <table className='hours-item'>
                        <tbody>
                            {Object.entries(holidays).map(([mmddyyyy, name], ndx) => {
                                return (<tr key={`${ndx}`}>
                                    <td className="setting__name">{mmddyyyy}</td>
                                    <td className="setting__content">{name}</td>
                                    <td>
                                        <button
                                            className="btn btn-theme btn-min btn-delete"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                const newHolidays = { ...holidays };
                                                delete newHolidays[mmddyyyy];
                                                setHolidays(newHolidays);
                                            }}
                                        >
                                            -
                                        </button>
                                    </td>
                                </tr>);
                            })}
                            <tr>
                                <td className="setting_name">
                                    <input
                                        type="text"
                                        key={`mmddyyyy_${Date.now()}`}
                                        id="holiday_mmddyyyy"
                                        className="input-theme input-theme-min"
                                        placeholder={`MM/DD/YYYY. e.g. ${dayjs().format("MM/DD/YYYY")}`}
                                        defaultValue={''} />
                                </td>
                                <td className="setting_content">
                                    <input
                                        type="text"
                                        key={`name_${Date.now()}`}
                                        id="holiday_name"
                                        className="input-theme input-theme-min"
                                        placeholder='holiday name, e.g. Christmas'
                                        defaultValue={''} />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-theme btn-min"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            const holiday_mmddyyyy = (document.getElementById(`holiday_mmddyyyy`) as HTMLInputElement).value;
                                            const holiday_name = (document.getElementById(`holiday_name`) as HTMLInputElement).value;
                                            const mmddyyyy = dayjs(holiday_mmddyyyy, "MM/DD/YYYY").format("MM/DD/YYYY");
                                            if (mmddyyyy === "Invalid date") {
                                                alert.set(`Holiday day should be in MM/DD/YYYY format`);
                                            }
                                            else if ((holiday_name || '') === '') {
                                                alert.set(`Holiday should have a name`);
                                            }
                                            else if (holidays[mmddyyyy]) {
                                                alert.set(`Holiday date '${mmddyyyy}' already exists`);
                                            }
                                            else {
                                                setHolidays({
                                                    ...holidays,
                                                    [mmddyyyy]: holiday_name
                                                });
                                            }
                                        }}
                                    >
                                        +
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    const onSaveAgency = (e: React.MouseEvent<HTMLButtonElement>, agencyPatch:Partial<AgencyType.Agency>): Promise<any> => {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/manager/agency','PUT',agencyPatch)
            .then(res => {
                if (!res || res.err)
                    throw Error(res?.err || `unknown error`);
                // Update the agency itself
                setWorkminutes(agencyPatch.workminutes!);
                setHolidays(agencyPatch.holidays!);
                tectransit.agency = agencyPatch;
                alert.set(`Agency is saved`, 5000);
            })
            .catch(err => {
                alert.set(`Cannot save agency (${err.message})`);
            });
    };
    return MenuItem.withMenuItem("Hours & Holidays", (alert_) => {
        alert = alert_;
        return (
            <div className="content">
                <div className="wrapper">
                    {getHoursElements()}
                    {getHolidaysElements()}
                    <div className="hours-btn">
                        <button className="btn btn-theme" onClick={e => onSaveAgency(e,{ workminutes, holidays })}>Save</button>
                    </div>
                </div>
            </div>
        );
    });
}
