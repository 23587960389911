import { Loader }           from "@googlemaps/js-api-loader"
import * as redux           from "redux";

import * as LatLngType      from 'shared/types/LatLng';
import * as UserType        from 'shared/types/User';
import * as AgencyType      from 'shared/types/Agency';
import Menu                 from 'utils/Menu';
import getApiPromise        from 'utils/getApiPromise';
import * as GeocoderType    from 'utils/Geocoder';
import * as reduxTypes      from "utils/redux";

class TecTransit {

    private clearConsoleInterval?       : number;
    // @ts-expect-error
    public  store                       : redux.Store<reduxTypes.State,redux.Action>;
    // @ts-expect-error
    public  geocoder                    : GeocoderType.Geocoder;
    public  menu                        : Menu   = { isOpen:false };
    public  readonly timeFormat         : string = "MM/DD/YYYY HH:mm";

    getLatLngKey( latlng:(google.maps.LatLngLiteral|google.maps.LatLng|undefined) ) : string {
        if( !latlng )
            return `n/a`;
        if( latlng instanceof google.maps.LatLng )
            latlng = latlng.toJSON();
        return LatLngType.toString(LatLngType.round(latlng,this.geocoder.latLngPrecision));
    }
    getWebsocket(
        pathname    : string,
        onClose     : ((event:any)=>void),
        onData      : ((data:Record<string,any>)=>void),
        onOpen?     : ((event:any)=>void)
    ) : WebSocket {
        const protocol = (window.location.protocol==="https:") ? "wss:" : "ws:";
        const url      = `${protocol}//${window.location.host}/${pathname}`;
        const ws       = new WebSocket(url);
        ws.onerror = (event) => {
            console.error(`Got event '${event.type}' from ${url}`);
        };
        ws.onclose = (event) => {
            console.log(`Got event '${event.type}' from ${url}`);
            if( typeof onClose === 'function' )
                onClose(event);
        };
        ws.onmessage = (event) => {
            if( typeof onData === 'function' )
                onData(JSON.parse(event.data));
        };
        ws.onopen = (event) => {
            console.log(`Got event '${event.type}' from ${url}`);
            if( typeof onOpen === 'function' )
                onOpen(event);
        };
        return ws;
    }
    getAgencyBoundary() : google.maps.LatLngLiteral[] {
        const boundaries = this?.agency?.boundaries || [];
        return boundaries[0].concat(...boundaries.slice(1));
    }
    getGoogleMap( mapDiv:HTMLElement, options:Partial<google.maps.MapOptions> ) : google.maps.Map {
        return new google.maps.Map(mapDiv,{
            zoomControl       : true,
            mapTypeControl    : false,
            scaleControl      : false,
            streetViewControl : false,
            fullscreenControl : false,
            rotateControl     : false,
            clickableIcons    : false,
            ...options
        });
    }
    get user() : UserType.User {
        if( !this.store )
            return {} as unknown as UserType.User;
        return this.store.getState().user;
    }
    get agency() : AgencyType.Agency {
        if( !this.store )
            return {} as unknown as AgencyType.Agency;
        return this.store.getState().agency;
    }
    set user( userPatch:Partial<UserType.User> ) {
        this.store?.dispatch({
            type    : reduxTypes.USER__UPDATE,
            payload : userPatch
        });
    }
    set agency( agencyPatch:Partial<AgencyType.Agency> ) {
        this.store?.dispatch({
            type    : reduxTypes.AGENCY__UPDATE,
            payload : agencyPatch
        });
    }
    roundSeconds( seconds:number ) {
        const timePrecision = (this.agency.max_lateness_seconds||300);
        return (Math.round(seconds/timePrecision)*timePrecision);
    }
    roundLatLng( latlng:LatLngType.LatLng ) {
        return LatLngType.round(latlng,this.geocoder.latLngPrecision);
    }
    private startGarbageMessagesJanitor() {
        // See https://stackoverflow.com/questions/39152877/consider-marking-event-handler-as-passive-to-make-the-page-more-responsive?noredirect=1&lq=1
        // If I do not do this on a timer then the messages accumulate and can eventually eat browser resources
        this.clearConsoleInterval = window.setInterval(() => {
            console.clear();
        },120000);
    }
    async init() : Promise<boolean> {
        const loader = new Loader({
            apiKey      : (process.env.REACT_APP_CLIENT_API_KEY||''),
            version     : "weekly",
            libraries   : ["places","geometry","geocoding"]
        });
        const userAndAgency = await Promise
            .all([
                loader.load().then(()=>google.maps.importLibrary("maps")),
                getApiPromise<UserType.Dehydrated>('/api/public/user'),
                getApiPromise<AgencyType.Agency>('/api/public/agency')
            ])
            .then( ([,dehydratedUser,agency]) => {
                if( !dehydratedUser || dehydratedUser.err ) {
                    window.location.href = `/auth/index?${(new URLSearchParams({err:(dehydratedUser?.err||'user is empty')})).toString()}`;
                    return [undefined,agency];
                }
                const user = UserType.hydrate(dehydratedUser);
                if( !user.roles )
                    user.roles = [];
                if( !user.roles.length )
                    user.roles = ["Passenger"];
                user.roles.sort();
                if( !agency || agency.err ) {
                    window.location.href = `/auth/index?${(new URLSearchParams({err:(agency?.err||'agency is empty')})).toString()}`;
                    return [user,undefined];
                }
                return [user,agency];
            });
        const user   = userAndAgency[0] as UserType.User;
        const agency = userAndAgency[1] as AgencyType.Agency;
        if( !user || !agency )
            return false;
        this.geocoder   = agency.passenger_reverse_geocode_api ? new GeocoderType.ApiGeocoder('/api/passenger/geocode/reverse') : new GeocoderType.GoogleGeocoder(agency.bounds);
        this.store      = redux.legacy_createStore<reduxTypes.State,reduxTypes.Action,{},{}>(
            redux.combineReducers({ 
                user    : reduxTypes.userReducer, 
                agency  : reduxTypes.agencyReducer, 
                menu    : reduxTypes.menuReducer 
            }) as unknown as redux.Reducer<reduxTypes.State,reduxTypes.Action>,
            {user,agency}
        );
        this.startGarbageMessagesJanitor();
        return true;
    }
}

const tecTransit = new TecTransit();

export default tecTransit;
