import * as LatLngType  from './LatLng';
import * as OrderType   from './Order';

//////////////////////////////////////////////////////////////////////////////////////////////
// database objects
//////////////////////////////////////////////////////////////////////////////////////////////
export interface DBService {
    _id                 : string;
    name                : string;
    monday              : number;
    tuesday             : number;
    wednesday           : number;
    thursday            : number;
    friday              : number;
    saturday            : number;
    sunday              : number;
    [key:string]        : any;
}

export interface Connection {
    from_trip_id        : number;
    to_trip_id          : number;
}

export interface DBShape extends LatLngType.LatLng {
    shape_id            : number;
    ndx                 : number;
    stop_id?            : number;   // This is a hint to the server to tell that this point of the route is a stop
}

export interface DBRoute {
    _id                 : string;   // Also a short name
    short_name?         : string;   // eg: "3"
    long_name           : string;   // eg: "Blue Line"
    description         : string;   // eg: "Blue Line - 3rd Street"
    type                : number;   // eg: 0, 1, 2 etc
}

export interface DBTrip {
    _id                 : number;
    service_id          : string;
    shape_id            : number;
    name                : string;   // eg: "Blue Line #3"
    route_name          : string;   // eg: red, green, blue etc
    block_id?           : string;   // sometimes stored in DB
    headsign?           : string;   // sometimes stored in DB
}

export interface DBStopTime {
    _id?                : (string|Record<string,any>);  // It is really mongodb.ObjectId but i do not want to import the mongodb types
    trip_id             : number;
    stop_id             : number;   // The same stop can happen on a trip multiple times with different stop_sequence
    arrival_time        : string;
    departure_time      : string;
    stop_sequence       : number;
    shape_dist_traveled : number;   // in meters
}

export interface DBStop extends LatLngType.LatLng {
    _id                 : number;
    name                : string;
    location_type?      : number;
}

export interface DBFare {
    _id                 : string;
    price               : number;
    currency_type?      : string;
    payment_method?     : string;
    transfers?          : number;
    transfer_duration?  : number;
}

export interface Stop extends DBStop {
    stop_time           : DBStopTime,
    // Fixed route itself, of course, operates without orders but sometimes our
    // agency tracks its own orders on external agency (eg. Caltrain) fixed route
    pickup_orders?      : OrderType.Order[];
    dropoff_orders?     : OrderType.Order[];
}

export interface Trip extends DBTrip {
    // `Trip` is what is stored in the database but the `Route` is used in the runtime.
    // It is the `Trip` with attached to it the stops and shapes
    stops               : Stop[];
    shapes              : DBShape[];
}

export interface LogStop {
    _id                 : number;  // this is the stop id
    stop_sequence       : number;
    start_seconds?      : number;
    end_seconds?        : number;
    passengers          : Record<string,number>;
    boardings           : Record<string,number>;
    apc_passengers?     : number;
    apc_boardings?      : number;
}

export interface Log {
    _id                 : any; /* mongodb.ObjectId */
    fares               : Record<string,number>;
    trip_id             : number;
    vehicle_id          : number;
    stops               : LogStop[];
}

//////////////////////////////////////////////////////////////////////////////////////////////
// client/server REST API and WS interfaces
//////////////////////////////////////////////////////////////////////////////////////////////
export interface LiveTrip extends DBTrip {
    // used in /fixedRoute/liveTrips
    vehicle_id          : number,
    stops               : Stop[],
}
export interface StopTrip extends DBTrip {
    // Used in /fixedRoute/stopTrips
    arrival_ms          : number;
    departure_ms        : number;
}
export interface TripCounts {
    resetMs             : number;                   // milliseconds when the counters were last reset
    tripId              : number;                   // trip Id
    seconds             : number;                   // seconds the counts are for
    passengers          : Record<string,number>;    // the key here is the passenger 'fare category' defined by Agency.fixed_route_fare
    boardings           : Record<string,number>;    // same key as above
    apcPassengers       : number;
    apcBoardings        : number;
}

//////////////////////////////////////////////////////////////////////////////////////////////
// other APIs
//////////////////////////////////////////////////////////////////////////////////////////////
export const hhmmRE = /^(\d{1,2}):(\d\d)(?::(\d\d))?$/;

export const getSecondsFromHHmm = ( hhmm:string ) => {
    const matches = hhmm.match(hhmmRE);
    if( !matches )
        throw Error(`'${hhmm}' does not match HH:mm`);
    const hours    = Number(matches[1]);
    const minutes  = (60*hours)+Number(matches[2]);
    const seconds  = (60*minutes)+Number(matches[3]||0);
    return seconds;
}

export const validateTrip = ( trip:Trip ) => {
    if( !trip )
        throw Error(`Cannot find trip`);
    if( !Array.isArray(trip.shapes) || trip.shapes.length<2 )
        throw Error(`Cannot find shapes for trip #${trip._id}`);
    if( !Array.isArray(trip.stops) || trip.stops.length<2 )
        throw Error(`Cannot find stops for trip #${trip._id}`);
    const shape_id = Number(trip.shape_id);
    if( !Number.isInteger(shape_id) )
        throw Error(`shape_id is invalid`);
    trip.stops.sort((s1,s2)=>(s1.stop_time.stop_sequence-s2.stop_time.stop_sequence)).reduce( (prev_stop_departure_seconds,stop,stop_ndx,stops) => {
        if( !stop.stop_time )
            throw Error(`Cannot find stop time for stop #${stop._id}`);
        if( !Number.isInteger(stop.stop_time.stop_sequence) )
            throw Error(`Stop sequence of stop ${stop._id} is invalid`);
        if( !hhmmRE.test(stop.stop_time.arrival_time) )
            throw Error(`Arrival time '${stop.stop_time.arrival_time}' of stop ${stop._id} is invalid`);
        if( !hhmmRE.test(stop.stop_time.departure_time) )
            throw Error(`Departure time '${stop.stop_time.departure_time}' of stop ${stop._id} is invalid`);
        const arrival_seconds   = getSecondsFromHHmm(stop.stop_time.arrival_time);
        if( arrival_seconds<prev_stop_departure_seconds )
            throw Error(`Arrival time '${stop.stop_time.departure_time}' of stop ${stop._id} should be after or equal to departure time for prev stop ${stops[stop_ndx-1]?._id}`);
        const departure_seconds = getSecondsFromHHmm(stop.stop_time.departure_time);
        // TODO: this will break if the trip is over midnight
        if( arrival_seconds>departure_seconds )
            throw Error(`Arrival time '${stop.stop_time.arrival_time}' should be before or equal to departure time for stop ${stop._id}`);
        return departure_seconds;
    },0);
    trip.shapes.forEach( shape => {
        if( shape.shape_id!==shape_id )
            throw Error(`Shape id ${shape.shape_id} does not match trip shape id ${shape_id}`);
        if( !LatLngType.isValid(shape) )
            throw Error(`Shape latlng is invalid`);
    });
}

export default Trip;
