import React              from 'react';
import { useParams }      from 'react-router-dom';

import * as UserType      from 'shared/types/User';
import * as OrderType     from 'shared/types/Order';
import Alert              from 'utils/Alert';
import tectransit         from 'utils/TecTransit';
import getApiPromise      from 'utils/getApiPromise';
import EditUserInfo       from 'components/EditUserInfo';
import OrderModal         from 'components/OrderModal';
import * as MenuItem      from 'components/MenuItem';

import UsersSearch        from 'Dispatcher/components/UsersSearch';
import Orders             from 'Dispatcher/components/Orders';
import StandingOrders     from 'Dispatcher/components/StandingOrders';
import DispatcherOrderMap from 'Dispatcher/components/DispatcherOrderMap';

export const User : React.FC<Record<string,any>> = props => {

    const params                    = useParams() as { id:string };
    const [loading,setLoading]      = React.useState<boolean>(true);
    const [user,setUser]            = React.useState<UserType.User|undefined>(undefined);
    const ordersComponent           = React.createRef<Orders>();
    const standingOrdersComponent   = React.createRef<StandingOrders>();

    let alert      = new Alert();

    React.useEffect(() => {
        getApiPromise<UserType.Dehydrated>('/api/dispatcher/user',"GET",undefined,{id:params.id})
            .then( user => {
                setLoading(false);
                setUser(UserType.hydrate(user));
            })
            .catch( err => {
                alert.set(`Cannot get user (${err.message})`);
            });
    },[params.id]);

    const onCreateUserOrder = ( order:OrderType.Order ) => {
        return getApiPromise<OrderType.Order>('/api/dispatcher/order',"POST",order,{user_id:params.id})
            .then( order => {
                if( !order )
                    throw Error(`server did not respond`);
                if( order.err )
                    throw order;
                // If order was created successfully, refresh the orders tables
                if( ordersComponent.current )
                    ordersComponent.current.fetchPage(1,[]);
                if( standingOrdersComponent.current )
                    standingOrdersComponent.current.fetchPage(1,[]);
                return order;
            });
    };

    const onUserChange = ( userPatch:UserType.User ) => {
        return getApiPromise("/api/dispatcher/user","PUT",userPatch)
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||'empty response');
                setUser({...user,...userPatch});
                if( userPatch._id===tectransit.user._id )
                    tectransit.user = userPatch;
            })
            .catch( err => {
                alert.set(`Cannot post user (${err.message})`);
            });
    };

    const onSendMessage = ( subject:string, text:string ) => {
        return getApiPromise("/api/dispatcher/message","POST",{user_id:user?._id,subject,text})
            .then( res => {
                if( res?.err )
                    throw Error(res?.err);
                alert.set('Message is sent');
            })
            .catch( err => {
                alert.set(`cannot send message (${err.message})`);
            });
    };

    return MenuItem.withMenuItem("Passenger",( alert_ ) => {
        alert = alert_;
        return (<>
            <UsersSearch/>
            {loading ? <p className="alert alert-primary" role="alert">Loading...</p>
                : user ? (
                    <div className="content container-fluid container">
                    <div key="userInfo" className="card">
                        <div className="card-body">
                            <EditUserInfo
                                user={user}
                                onSendMessage={onSendMessage}
                                onUserChange={onUserChange}/>
                        </div>
                    </div>
                    { (user.roles||[]).includes("Passenger") && tectransit.agency.on_demand_enabled && (
                        <>
                            <div key="newOrder" className="card">
                                <div className="card-body">
                                    <h4>New Order</h4>
                                    <DispatcherOrderMap
                                        orderTypes      = { Object.keys(OrderModal.orderTypeOptions) as OrderType.Type[] }
                                        dispatcherMode  = { true }
                                        user            = { user }
                                        order           = { {
                                            status                  : 'unknown',
                                            type                    : 'asap',
                                            seats                   : (user.special_accommodations?.wheelchair?0:1)+(user.special_accommodations?.personalcareattendant?1:0),
                                            wheelchairs             : (user.special_accommodations?.wheelchair?1:0),
                                            special_accommodations  : (user.special_accommodations||{}),
                                            pickup                  : {},
                                            dropoff                 : {}
                                        } as OrderType.Order }
                                        style       = {{
                                            position : 'relative',
                                            height   : '600px'
                                        }}
                                        onCreateOrder   = { onCreateUserOrder }/>
                                </div>
                            </div>
                            <div key="standingOrders" className="card">
                                <div className="card-body">
                                    <h4>Standing Orders</h4>
                                    <StandingOrders
                                        ref={standingOrdersComponent}
                                        user_id={user._id}/>
                                </div>
                            </div>
                            <div key="orders" className="card">
                                <div className="card-body">
                                    <h4>Orders</h4>
                                    <Orders
                                        ref={ordersComponent}
                                        user_id={user._id}/>
                                </div>
                            </div>
                        </>
                    )}
                    </div>
                ) : (
                    <div className="alert alert-warning" role="alert">{`User #${params.id} is not found`}</div>
                )
            }
        </>);
    });
}

export default User
