import React from 'react';

import Agency          from 'shared/types/Agency';

import Alert           from 'utils/Alert';
import tectransit      from 'utils/TecTransit';
import MapOptions      from 'utils/MapOptions';
import getApiPromise   from 'utils/getApiPromise';
import * as MenuItem   from 'components/MenuItem';

// This loosely follows https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu
interface Props {
    title                  : string;
    boundariesPropertyName : ('boundaries'|'issue333_paratransit_areas');
}

export default class BoundariesEditor extends React.Component {

    // @ts-expect-error
    public  props          : Props;

    private alert          : Alert;
    private googleMap?     : google.maps.Map;
    private mapBoundaries? : google.maps.Polyline[];

    constructor( props:Props ) {
        super(props);
        this.alert = new Alert();
    }
    // public
    componentDidMount() {
        // The center of map is arithmetic average of all depots
        const strokeColors = ["red","green","blue","yellow","pink","black","DarkCyan","DarkGoldenRod","DarkSalmon","DarkViolet"];
        this.googleMap     = tectransit.getGoogleMap(
            document.getElementById("map")!,
            new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),0.025))
        );
        this.mapBoundaries = (tectransit.agency[this.props.boundariesPropertyName]||[]).map( (boundary,ndx) => {
            return new window.google.maps.Polyline({
                map           : this.googleMap,
                path          : boundary,
                editable      : true,
                strokeColor   : strokeColors[ndx%strokeColors.length],
                strokeOpacity : 1.0,
                strokeWeight  : 3,
            });
        });
    }
    onSaveAgency( e:any, agencyPatch:Partial<Agency> ) {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/manager/agency','PUT',agencyPatch)
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||'unknown error');
                tectransit.agency = agencyPatch;
                this.alert.set(`Agency is saved`,3000);
            })
            .catch( err => {
                this.alert.set(`Cannot post to agency (${err.message})`);
            });
    }
    render() {
        return MenuItem.withMenuItem(this.props.title, (alert) => {
            this.alert = alert;
            return (
                <div className="content">
                    <div className="wrapper">
                        <div className="section-card top-1">
                            <div className="section-card-wrap top-1">
                                <div id="map" style={{height:650}}></div>
                                <br/>
                                <button className="btn-theme" onClick={(e)=>this.onSaveAgency(e,{
                                    [this.props.boundariesPropertyName] : this.mapBoundaries!.map(boundary=>boundary.getPath().getArray().map(ll=>ll.toJSON()))
                                })}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}
