import React              from 'react';

import * as images        from 'images';
import tectransit         from 'utils/TecTransit';
import MapOptions         from 'utils/MapOptions';

export interface Props {
    inputProps : Record<string,any>;
    onSelect   : (acp:google.maps.places.PlacePrediction)=>void;
}
interface State {
    address?                : google.maps.places.FormattableText;
    autocompleteSuggestions?: google.maps.places.AutocompleteSuggestion[];
}
export default class PlacesAutocomplete extends React.Component<Props,State> {

    public state       : State;

    constructor( props:Props ) {
        super(props);
        this.state = {
        };
    }
    componentDidUpdate( prevProps:Props ) {
        if( prevProps.inputProps.value!==this.props.inputProps.value ) {
            this.setState({
                address : (this.props.inputProps.value||'')
            });
        }
    }
    render() {
        return [
            <input
                placeholder     = "Search Places..."
                key             = "acInput"
                className       = "input-theme"
                type            = "text"
                {...this.props.inputProps}
                value           = {this.state.address?.text}
                onChange        = {(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    const address = e.target.value;
                    this.setState({address});
                    if( address.length>3 ) {
                        google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions({
                            input               : address,
                            locationRestriction : MapOptions.getBounds(tectransit.getAgencyBoundary())
                        }).then( v => {
                            this.setState({
                                autocompleteSuggestions : v.suggestions
                            });
                        }).catch( (err:Error) => {
                            console.error(`Google AutocompletionService error:`,err);
                        });
                    }
                    else {
                        this.setState({
                            autocompleteSuggestions : undefined
                        });
                    }
                }}/>,
            ...((this.state.autocompleteSuggestions||[]).slice(0,5).map( (autocompleteSuggestion,ndx) => {
                if( !autocompleteSuggestion.placePrediction?.text )
                    return null;
                return (
                    <div
                        key       = {`prediction_${ndx}`}
                        className = "addressPrediction__item"
                        style     = {{cursor:'pointer'}}
                        onClick   = {(e:any) => {
                            this.setState({
                                address                 : (autocompleteSuggestion.placePrediction?.text||undefined),
                                autocompleteSuggestions : undefined
                            })
                            this.props.onSelect(autocompleteSuggestion.placePrediction!);
                        }}>
                        <images.PlacesAutocomplete/>
                        <div className="addressPrediction__name">{autocompleteSuggestion.placePrediction?.text?.text}</div>
                    </div>
                )
            }))
        ];
    }
};
