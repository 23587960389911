import { readAndCompressImage } from 'browser-image-resizer';
import React,{ ChangeEvent }    from 'react';
import { Link }                 from "react-router-dom";
import CurrencyInput            from 'react-currency-input-field';
import QRCode                   from 'qrcode';

import * as UserType            from 'shared/types/User';
import dayjs                    from 'shared/utils/day-timezone';

import * as images              from 'images';
import tectransit               from 'utils/TecTransit';
import Alert                    from 'utils/Alert';
import getApiPromise            from 'utils/getApiPromise';
import * as ViewUserInfoType    from 'components/ViewUserInfo';
import PlacesAutocomplete       from 'components/PlacesAutocomplete';
import * as MenuItem            from 'components/MenuItem';

export interface Props extends ViewUserInfoType.Props {
    onSendMessage? : ( subject:string, text:string ) => Promise<any>;
    onUserChange   : ( user:UserType.User ) => Promise<any>;
}

export default class EditUserInfo extends ViewUserInfoType.ViewUserInfo {

    private imageWidthPx            = 150;
    private imageHeightPx           = 150;
    private alert                   : Alert;
    private placePrediction?        : google.maps.places.PlacePrediction;
    private boardingCodeSrc?        : string;

    // @ts-expect-error TS2564
    public  props                   : Props;
    constructor( props:Props ) {
        super(props);
        this.alert         = new Alert();
        if( tectransit.agency.issue422_public_key ) {
            getApiPromise<Record<string,any>>("/api/passenger/qrcode/signature","GET").then( res => {
                if( res.err ) {
                    console.error(`Cannot get qrcode signature (${res.err})`);
                }
                else {
                    QRCode.toDataURL(JSON.stringify(res)).then( boasrdingCodeSrc => {
                        this.boardingCodeSrc = boasrdingCodeSrc;
                        this.forceUpdate();
                    })
                }
            });
        }
    }
    isValidUser = ( user:UserType.User ) => {
        const emailRE               = /^[A-Z0-9+._-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+$/i;
        const phoneRE               = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
        const adaCerticiationRE     = /^\d+$/;
        const drivingLicenseNumberRE = /^[A-Z]{1}\d{7,}$/;
        const isValidDate = ( s:(undefined|string), needInFuture:(boolean|undefined) ) : string => {
            if( (s||'')==='' )
                return ''; // means no change
            const m = dayjs(s,"MM/DD/YYYY");
            if( !m.isValid() )
                return `date should be in MM/DD/YYYY format`;
            if( needInFuture===true ) {
                if( !m.isAfter() )
                    return `date needs to be in the future`;
            }
            else if( needInFuture===false ) {
                if( !m.isBefore() )
                    return `date needs to be in the past`;
            }
            return '';
        }
        const isValidRates = ( rates:(UserType.ChangedRateLog[]|undefined) ) : string => {
            if( !rates )
                return '';
            if( !Array.isArray(rates) )
                return `rates should be an array`;
            const last = rates[rates.length-1];
            if( !last )
                return `There should be at least one rate`;
            if( isNaN(last.rate!) )
                return `last rate should be a number`;
            const prev = rates[rates.length-2];
            if( prev ) {
                if( Number.isNaN(prev.rate) )
                    return `prev rate should be a number`;
                if( prev.rate!.toFixed(2)===last.rate!.toFixed(2) )
                    return `last rate should be different from prev rate ${last.rate}`;
            }
            return '';
        }
        if( (user.given_name||'').trim()==='' )
            return `First name cannot be empty`;
        if( (user.family_name||'').trim()==='' )
            return `Last name cannot be empty`;
        if( !emailRE.test(user.email||'') )
            return `Email address is invalid`;
        if( (user.phone||'')!=='' && !phoneRE.test(user.phone!) )
            return `Phone number is invalid`;
        if( (user.ada_certification||'')!=='' && !adaCerticiationRE.test(user.ada_certification!) )
            return `ADA Certification is invalid`;
        if( (user.roles||[]).includes("Driver") ) {
            if( (user.driving_license_number||'')!=='' && !drivingLicenseNumberRE.test(user.driving_license_number!) )
                return `driving license number is invalid`;
            let tmp;
            if( (tmp=isValidDate(user.driving_license_expiration,true))!=='' )
                return `driving license expiration is invalid (${tmp})`;
            if( (tmp=isValidDate(user.last_training_date,false))!=='' )
                return `Last training date is invalid (${tmp})`;
            if( (tmp=isValidDate(user.w4_date,false))!=='' )
                return `W4 date is invalid (${tmp})`;
            if( tectransit.user._id!==user._id ) {
                if( (tmp=isValidRates(user.regular_rate))!=='' )
                    return `Regular rate is invalid (${tmp})`;
                if( (tmp=isValidRates(user.overtime_rate))!=='' )
                    return `Overtime rate is invalid (${tmp})`;
            }
        }
        if( user.roles.includes("Manager") && !user.roles.includes("Dispatcher") )
            return `Please either add role "Dispatcher" or remove role "Manager" (because every Manager should also be a Dispatcher)`;
        return Object.entries(user.addresses||{}).map( ([name,address]) => {
            if( name.trim()==='' )
                return `address name cannot be empty`;
            if( (address.address||'').trim()==='' )
                return `address '${name}' cannot be empty`;
            if( (address.location)===undefined )
                return `address '${name}' should have geo coordinates`;
            return ``;
        }).find(msg => msg.length)||'';
    }
    saveUser = ( delta:Partial<UserType.User> ) => {
        const user = {
            ...this.state.user,
            ...delta
        } as UserType.User;
        const result = this.isValidUser(user);
        if( result!=='' ) {
            this.alert.set(`User is invalid (${result})`);
            // Even though the whole user may be invalid, we still need to update the state
            // we just won't save it to the server
            this.setState({user});
        }
        else {
            this.alert.set(undefined);
            this.props.onUserChange(user).then( () => {
                this.setUser(user);
            });
        }
    }
    onDeleteAddress = ( addressName:string ) => {
        const addresses = this.state.user?.addresses||{};
        delete addresses[addressName];
        return this.saveUser({addresses});
    }
    onCreateAddress = ( addressName:string, pp?:google.maps.places.PlacePrediction ) => {
        if( addressName.trim()==='' ) {
            this.alert.set(`Please choose a name for your new pre-saved address`);
        }
        else if( !pp ) {
            this.alert.set(`Please start typing address to choose from a list of suggestions`);
        }
        else {
            tectransit.geocoder.getPlaceidLookupPromise(pp.placeId).then( geocoderResult => {
                this.setState({
                    autocompleteAddress : ''
                });
                this.saveUser({
                    addresses : {
                        ...this.state.user?.addresses,
                        [addressName.trim()] : {
                            address  : geocoderResult.formatted_address,
                            location : geocoderResult.geometry.location.toJSON()
                        }
                    }
                });
            });
        }
    }
    changeRate = (e:ChangeEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const newRate : UserType.ChangedRateLog = {
            seconds : Date.now()/1000,
            rate    : Number((e.target as HTMLInputElement).value.trim().replace(/[^0-9.]/g,''))
        }
        if( e.target.id==='regular_rate' ) {
            this.saveUser({
                regular_rate : [...(this.state.user?.regular_rate||[]),newRate]
            });
        }
        else if( e.target.id==='overtime_rate' ) {
            this.saveUser({
                overtime_rate : [...(this.state.user?.overtime_rate||[]),newRate]
            });
        }
    }
    render() {
        if( !this.state.user )
            return <div>Loading user...</div>;
        const user       = this.state.user;
        const vehicle_id = tectransit.agency.vehicles_by_driver_id[this.state.user._id];
        const vehicle    = (this.state.vehicles||[]).find(v=>(v._id===vehicle_id));
        const getRow = ( name:any, value:any, key?:string ) => {
            return (
              <div key={key||name} className="profile-col">
                  <div className="profile-left">{name}</div>
                  <div className="profile-right">{value}</div>
              </div>
            );
        };
        const getSectionCard = ( title:string, contents:any ) => {
            return (
                <div className="section-card">
                    <div className="section-card__header">
                        <div className="section-card__title">{title}</div>
                    </div>
                    <div className="user-profile user-profile__info user-profile__column">
                        {contents}
                    </div>
                </div>
            );
        };
        // Drivers should not be able to change their profiles unless they also have
        // another privileged role in the agency (i.e. "Manager" or "Dispatcher")
        const contactsCard = ((user.roles||[]).filter(r=>(r!=='Passenger')).join(',')==="Driver") ? [
            getRow("First Name", user.given_name || '??'),
            getRow("Last Name", user.family_name || '??'),
            getRow("Email", user.email || '??'),
            getRow("Phone", user.phone || '??')
        ] : getSectionCard("Contacts",<>
            <div className="field-group">
                <label>
                    First Name
                    <input
                        type="text"
                        placeholder="Given name"
                        className="input-theme input-theme-min"
                        defaultValue={user.given_name||''}
                        size={25}
                        maxLength={60}
                        onBlur={(e)=>this.saveUser({given_name:e.target.value.trim()})}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Last Name
                    <input
                        type="text"
                        placeholder="Family name"
                        className="input-theme input-theme-min"
                        defaultValue={user.family_name||''}
                        size={25}
                        maxLength={60}
                        onBlur={(e)=>this.saveUser({family_name:e.target.value.trim()})}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Email
                    <input
                        type="text"
                        placeholder="Email Address"
                        className="input-theme input-theme-min"
                        defaultValue={user.email||''}
                        size={25}
                        maxLength={60}
                        onBlur={(e)=>this.saveUser({email:e.target.value.trim()})}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Phone
                    <input
                        type="text"
                        placeholder="Phone Number"
                        className="input-theme input-theme-min"
                        defaultValue={user.phone||''}
                        size={25}
                        maxLength={25}
                        onBlur={(e)=>this.saveUser({phone:e.target.value.trim()})}/>
                </label>
            </div>
        </>);
        const agencyDemographicsProfile = tectransit.agency.demographics_profile||{};
        const userDemographicsProfile   = user.demographics_profile||{};
        const demographicsCard = (user.roles||[]).includes("Passenger") && (Object.keys(agencyDemographicsProfile).length>=1) && getSectionCard(
            "Demographics",
            Object.entries(agencyDemographicsProfile).map( ([fieldName,fieldValues]) => {
                return (
                    <div key={`demographics_${fieldName}`} className="field-group">
                        <label>
                            {fieldName}
                            <select
                                id          = {`id_${fieldName}`}
                                className   = "input-theme input-theme-min"
                                value       = {userDemographicsProfile[fieldName]}
                                onChange    = {(e)=>this.saveUser({
                                    demographics_profile : {
                                        ...userDemographicsProfile,
                                        [fieldName] : e.target.value.trim()
                                    }
                                })}>{fieldValues.map(s=><option key={s} value={s}>{s}</option>)}
                            </select>
                        </label>
                    </div>
                );
            })
        );
        const rolesCard = tectransit.user.roles.includes("Dispatcher") && (tectransit.user._id!==user._id) && getSectionCard(
            "Roles",
            (["Passenger","Driver","Maintenance","Dispatcher","Manager","Observer"] as UserType.Role[]).map( (role,role_ndx) => {
                // "Passenger" role is only available if on-demand is enabled or when FR passengers are authenticated
                // (i.e. issue422_public_key is set)
                if( role==="Passenger" && !tectransit.agency.on_demand_enabled && !tectransit.agency.issue422_public_key )
                    return null;
                return (
                    <div key={`role_${role_ndx}`} className="field-group">
                        <input
                            type="checkbox"
                            id = { role }
                            checked={(user.roles||[]).includes(role)}
                            onChange={(e)=>this.saveUser({
                                roles : [
                                    ...(user.roles||[]).filter(r=>(r!==role)),
                                    ...(e.target.checked?[role]:[])
                                ]
                            })}
                        />
                        <label htmlFor={ role } className="checkbox">{ role }</label>
                    </div>
                );
            })
        );
        const boardingCodeCard = this.boardingCodeSrc && (tectransit.user._id===user._id) && tectransit.user.roles.includes("Passenger") && getSectionCard(
            "Boarding Code",
            <img alt='boarding code' style={{borderRadius:'unset'}} src={this.boardingCodeSrc}></img>
        );
        // pre-saved addresses do not make sense if the user is only allowed to choose from a set of given locations
        const onlyGivenLatLngs      = tectransit.agency.pickup_latlngs_enabled && tectransit.agency.dropoff_latlngs_enabled && Array.isArray(tectransit.agency.allowed_latlngs);
        const presavedAddressesCard = (user.roles||[]).includes("Passenger") && 
            !onlyGivenLatLngs && 
            tectransit.agency.on_demand_enabled &&
            getSectionCard("Addresses",[
                Object.entries(user.addresses||{}).map( ([address_name,address],address_ndx) => {
                    return getRow(address_name,(
                        <div className="address-view">
                            <images.DeleteAddress onClick={()=>this.onDeleteAddress(address_name)}/>
                            <a key="address" className="adresslink" href={"https://www.google.com/maps/place/"+address.address}>{address.address}</a>
                        </div>
                    ),`address_${address_ndx}`);
                }),
                getRow(<input type="text" className="input-theme input-theme-min" placeholder="Address Name" id="address_name" size={15} maxLength={20}/>,<>
                    <div>
                        <PlacesAutocomplete
                            key        = "placesAutocomplete"
                            inputProps = {{
                                value       : this.state.autocompleteAddress,
                                className   : 'input-theme input-theme-min'
                            }}
                            onSelect = {(pp:google.maps.places.PlacePrediction) => {
                                this.setState({
                                    autocompleteAddress : pp.mainText?.text||''
                                });
                                this.placePrediction = pp;
                            }}
                        />
                        <span className="inline-flex">
                            <images.AddAddress onClick={()=>this.onCreateAddress((document.getElementById('address_name') as HTMLInputElement).value,this.placePrediction)}/>
                        </span>
                    </div>
                </>,"(new)")
            ]);
        const driverCard = (user.roles||[]).includes("Driver") && getSectionCard(
            "Driver",
            (tectransit.user.roles.includes("Manager") || (tectransit.user._id===user._id)) ? [
                getRow("Driven Vehicle",<Link to="/Manager/Config/Vehicles">{vehicle ? `Fleet #${vehicle!._id}` : 'not assigned'}</Link>),
                <div key="dlNumber" className="field-group">
                    <label>
                        DL Number
                        <input
                            type="text"
                            placeholder="B1234567"
                            className="input-theme input-theme-min"
                            defaultValue={user.driving_license_number||''}
                            size={25}
                            maxLength={25}
                            onBlur={(e)=>this.saveUser({driving_license_number:e.target.value.trim()})}/>
                    </label>
                </div>,
                <div key="dlType" className="field-group">
                    <label>
                        DL Type
                        <select
                            value={user.driving_license_type||'B'}
                            className="input-theme input-theme-min"
                            onChange={(e)=>this.saveUser({driving_license_type:e.target.value.trim()})}>{["A","B","C"].map(s=><option key={s} value={s}>{s}</option>)}
                        </select>
                    </label>
                </div>,
                <div key="dlExpiration" className="field-group">
                    <label>
                        DL Expiration
                        <input
                            type="text"
                            placeholder="MM/DD/YYYY"
                            defaultValue={user.driving_license_expiration||''}
                            size={25}
                            className="input-theme input-theme-min"
                            maxLength={25}
                            onBlur={(e)=>this.saveUser({driving_license_expiration:e.target.value.trim()})}/>
                    </label>
                </div>,
                <div key="lastTrainingDate" className="field-group">
                    <label>
                        Last Training Date
                        <input
                            type="text"
                            placeholder="MM/DD/YYYY"
                            className="input-theme input-theme-min"
                            defaultValue={user.last_training_date||''}
                            size={25}
                            maxLength={25}
                            onBlur={(e)=>this.saveUser({last_training_date:e.target.value.trim()})}/>
                    </label>
                </div>,
                <div key="w4Date" className="field-group">
                    <label>
                        W-4 Date
                        <input
                            type="text"
                            placeholder="MM/DD/YYYY"
                            className="input-theme input-theme-min"
                            defaultValue={user.w4_date||''}
                            size={25}
                            maxLength={25}
                            onBlur={(e)=>this.saveUser({w4_date:e.target.value.trim()})}/>
                    </label>
                </div>,
                (tectransit.user._id===user._id) ? [
                    // User can only see his/her rates
                    getRow("Regular Rate", `$ ${(user.regular_rate?user.regular_rate[user.regular_rate.length-1].rate||0:0).toFixed(2)}`),
                    getRow("Overtime Rate", `$ ${(user.overtime_rate?user.overtime_rate[user.overtime_rate.length-1].rate||0:0).toFixed(2)}`)
                ] : [
                    // Managers can change user rates
                    <div key="regularRate" className="field-group">
                        <label>
                            Regular Rate
                            <CurrencyInput
                                id="regular_rate"
                                name="regular_rate"
                                placeholder="$1,234,567"
                                className="input-theme input-theme-min"
                                prefix={'$'}
                                decimalScale={2}
                                defaultValue={user.regular_rate?user.regular_rate[user.regular_rate.length-1].rate : ""}
                                onBlur={this.changeRate}
                            />
                        </label>
                    </div>,
                    <div key="overtimeRate" className="field-group">
                        <label>
                            Overtime Rate
                            <CurrencyInput
                                id="overtime_rate"
                                name="overtime_rate"
                                placeholder="$1,234,567"
                                className="input-theme input-theme-min"
                                prefix={'$'}
                                decimalScale={2}
                                defaultValue={user.overtime_rate?user.overtime_rate[user.overtime_rate.length-1].rate : ""}
                                onBlur={this.changeRate}
                            />
                        </label>
                    </div>
                ]
            ] : [
                getRow("Driven Vehicle", vehicle ? `Fleet #${vehicle!._id}` : 'not assigned'),
                getRow("DL Number", user.driving_license_number || ''),
                getRow("DL Type", user.driving_license_type || 'B'),
                getRow("DL Expiration", user.driving_license_expiration || ''),
                getRow("Last Training Date", user.last_training_date || ''),
                getRow("W-4 Date", user.w4_date || ''),
                getRow("Regular Rate", `$ ${(user.regular_rate?user.regular_rate[user.regular_rate.length - 1]?.rate||0:0).toFixed(2)}`),
                getRow("Overtime Rate", `$ ${(user.overtime_rate?user.overtime_rate[user.overtime_rate.length - 1]?.rate||0:0).toFixed(2)}`)
            ]
        );
        const photoCard = getSectionCard(
            "Photo",
            ((user._id!==tectransit.user._id) ? <img alt='user pic' src={`${user.picture}?${Date.now()}`}></img> : (
                <div>
                    <img
                        id          = "userPhoto" 
                        alt         = "user pic"
                        style       = {{width:`${this.imageWidthPx}px`,height:`${this.imageHeightPx}px`}}
                        src         = {`${user.picture}?${Date.now()}`}
                    />
                    <label
                        className   = "btn-theme btn-small" 
                        style       = {{width:`${this.imageWidthPx}px`}}
                    >
                        Change
                        <input
                            style   = {{opacity:0}} 
                            type    = "file" 
                            accept  = "image/*"
                            onChange= {(e:ChangeEvent<HTMLInputElement>)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                const file      = (e.target.files||[])[0];
                                if( !file )
                                    return;
                                readAndCompressImage(file,{maxWidth:this.imageWidthPx,maxHeight:this.imageHeightPx})
                                    .then( resized => {
                                        const formData = new FormData();
                                        formData.append('picture',resized);
                                        formData.append('user_id',String(user._id));
                                        const url = (user._id===tectransit.user._id) ? "/api/passenger/picture" : "/api/dispatcher/user/picture";
                                        return fetch(url,{method:'POST',body:formData}).then(res=>res.json())
                                            .then( res => {
                                                if( res.err )
                                                    throw Error(res.err);
                                                const userPhoto = document.getElementById('userPhoto') as HTMLImageElement;
                                                if( userPhoto )
                                                    userPhoto.src = URL.createObjectURL(resized);
                                                const profileImage = document.getElementById('profile__image') as HTMLImageElement;
                                                if( profileImage )
                                                    profileImage.src = URL.createObjectURL(resized); 
                                            })
                                            .catch( err => {
                                                this.alert.set(`Cannot upload image (${err.message})`);
                                            })
                                    })
                                    .catch( err => {
                                        this.alert.set(`Cannot resize image (${err.message})`);
                                    });
                            }}
                        />
                    </label>
                </div>
            ))
        );
        const accomodationsCard = (user.roles||[]).includes("Passenger") && (tectransit.agency.accommodations.length>=1) && getSectionCard(
            "Special Accommodations",
            tectransit.agency.accommodations.map( (accommodation,accommodation_ndx) => {
                const acc_lower = accommodation.replace(/\W/g, '').toLowerCase();
                return (
                    <div key={`accommodation_${accommodation_ndx}`} className="field-group">
                        <input
                            type="checkbox"
                            id={ accommodation }
                            checked={(user.special_accommodations || {})[acc_lower] === true}
                            onChange={(e) => this.saveUser({
                                special_accommodations: {
                                    ...user.special_accommodations,
                                    [acc_lower]: e.target.checked
                                }
                            })}/>
                        <label htmlFor={ accommodation } className="checkbox">{ accommodation }</label>
                    </div>
                );
            })
        );
        const transportationModesCard = (user.roles||[]).includes("Passenger") && 
            (tectransit.agency.modes_of_transportation.filter(mt=>(mt!=="Bus")).length>=1) && 
            tectransit.agency.on_demand_enabled &&
            getSectionCard(
                "Modes Of Transportation",
                tectransit.agency.modes_of_transportation.map( (mode,mode_ndx) => {
                    const mode_lower = mode.replace(/\W/g,'').toLowerCase();
                    return (
                        <div key={`mode_${mode_ndx}`} className="field-group">
                            <input
                                type="checkbox"
                                id={ mode }
                                checked={(user.mode_of_transportation || {})[mode_lower] === true}
                                onChange={(e) => this.saveUser({
                                    mode_of_transportation: {
                                        ...user.mode_of_transportation,
                                        [mode_lower]: e.target.checked
                                    }
                                })}/>
                            <label htmlFor={ mode } className="checkbox">{ mode }</label>
                        </div>
                    );
                })
            );
        const miscCard = (user.roles||[]).includes("Passenger") && getSectionCard("Misc",<>
            ADA Certification
            <div className="field-group">
                <label>
                    Number
                    <input
                        type="text"
                        size={25}
                        maxLength={25}
                        placeholder="ADA Certification Number"
                        className="input-theme input-theme-min"
                        defaultValue={user.ada_certification||''}
                        onBlur={(e)=>this.saveUser({ada_certification:e.target.value.trim()})}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Issue Date (MM/DD/YYYY)
                    <input
                        type="text"
                        size={25}
                        maxLength={25}
                        placeholder="ADA Issue Date"
                        className="input-theme input-theme-min"
                        defaultValue={dayjs(user.ada_certification_issued||Date.now()).format('MM/DD/YYYY')}
                        onBlur={(e)=> {
                            const ada_certification_issued = (new Date(e.target.value.trim())).valueOf();
                            if( isNaN(ada_certification_issued) )
                                return this.alert.set(`The ADA certification issue date should be a date`);
                            return this.saveUser({ada_certification_issued});
                        }}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Expiry Date (MM/DD/YYYY)
                    <input
                        type="text"
                        size={25}
                        maxLength={25}
                        placeholder="ADA Expiry Date"
                        className="input-theme input-theme-min"
                        defaultValue={dayjs(user.ada_certification_expiry||Date.now()).format('MM/DD/YYYY')}
                        onBlur={(e)=>{
                            const ada_certification_expiry = (new Date(e.target.value.trim())).valueOf();
                            if( isNaN(ada_certification_expiry) )
                                return this.alert.set(`The ADA certification expiry date should be a date`);
                            return this.saveUser({ada_certification_expiry})
                        }}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    On Vacation Till (MM/DD/YYYY)
                    <input
                        type="text"
                        size={25}
                        maxLength={25}
                        placeholder="First Date after Vacation"
                        className="input-theme input-theme-min"
                        defaultValue={dayjs(user.on_vacation_till||0).tz(tectransit.agency.time_zone).format('MM/DD/YYYY')}
                        onBlur={(e)=>{
                            // User enters the date in user time zone. Which is likely to be agency time zone.
                            // We need to translate the date to UTC for comparing with UTC dates elsewhere.
                            const on_vacation_till = dayjs.tz(new Date(e.target.value.trim()),tectransit.agency.time_zone).valueOf();
                            if( isNaN(on_vacation_till) )
                                return this.alert.set(`First day after vacation should be a date`);
                            return this.saveUser({on_vacation_till})
                        }}/>
                </label>
            </div>
            {tectransit.agency.on_demand_enabled && (<div className='field-group'>
                <input
                    type="checkbox"
                    id='special_accommodations_on_order'
                    checked={user.special_accommodations_on_order||false}
                    onChange={(e)=>this.saveUser({special_accommodations_on_order : e.target.checked})}/>
                <label htmlFor={'special_accommodations_on_order'} className="checkbox">Show Special Accommodations on Ordering</label>
            </div>)}
        </>);
        const sendMessageCard = tectransit.user.roles.includes("Dispatcher") && this.props.onSendMessage && getSectionCard("Send Message",<>
            <div className="field-group">
                <label>
                    Subject
                    <input
                        id={`send-subject`}
                        type="text"
                        size={25}
                        maxLength={25}
                        placeholder="Subject"
                        className="input-theme input-theme-min"
                        defaultValue={`Update from ${tectransit.agency.name} transportation`}/>
                </label>
            </div>
            <div className="field-group">
                <label>
                    Text
                    <input
                        id={`send-text`}
                        type="text"
                        size={140}
                        maxLength={140}
                        placeholder="Text of the message"
                        className="input-theme input-theme-min"
                        defaultValue={''}/>
                </label>
            </div>
            <div className="field-group">
                <button type="button" className="btn-theme btn-small" onClick={(e:any) => {
                    const sendSubject = (document.getElementById(`send-subject`) as HTMLInputElement).value;
                    const sendText    = (document.getElementById(`send-text`) as HTMLInputElement).value;
                    if( sendText.trim()==='' ) {
                        this.alert.set(`Please provide the message text`);
                    }
                    else {
                        (this.props.onSendMessage!)(sendSubject,sendText);
                    }
                }}>
                    Send
                </button>
            </div>
        </>);
        return MenuItem.withAlert( alert => {
            this.alert = alert;
            return (<>
                <div className="section-card__wrapper">
                    {(contactsCard || demographicsCard || rolesCard) && (
                        <div className="section-card-inner">
                            {contactsCard}
                            {demographicsCard}
                            {rolesCard}
                            {boardingCodeCard}
                        </div>
                    )}
                    {(accomodationsCard || transportationModesCard || presavedAddressesCard) && (
                        <div className="section-card-inner">
                            {photoCard}
                            {accomodationsCard}
                            {transportationModesCard}
                            {presavedAddressesCard}
                        </div>
                    )}
                    {(driverCard || miscCard || sendMessageCard ) && (
                        <div className="section-card-inner">
                            {miscCard}
                            {driverCard}
                            {sendMessageCard}
                        </div>
                    )}
                </div>
            </>);
        });
    }
}