import React                            from 'react';

import * as OrderType                   from 'shared/types/Order';
import * as WSPayloadType               from 'shared/types/WSPayload';
import dayjs                            from 'shared/utils/day-timezone';
import tectransit                       from 'utils/TecTransit';
import MapMarker                        from 'utils/MapMarker';
import * as ParatransitOrderMapType     from 'components/ParatransitOrderMap';

import PlacesAutocomplete               from 'components/PlacesAutocomplete';

export interface Props extends ParatransitOrderMapType.Props {
    onCreateOrder( order:OrderType.Order )    : Promise<any>;
}

export default class DispatcherOrderMap extends ParatransitOrderMapType.ParatransitOrderMap {

    private currentSeconds?  : number;
    private startUtcSeconds? : number;
    private ws?              : WebSocket = undefined;

    // @ts-expect-error
    public  props            : Props;

    // private
    private closeWebSocket( reason:string ) {
        if( this.ws ) {
            try {
                this.ws.close(3001,reason);
            }
            catch (err) {
                console.error(`Cannot close websocket`,err);
            }
            this.ws = undefined;
        }
    }
    private getWebsocket( timeout=1000 ) : WebSocket {
        const onClose = () => {
            timeout *= 1.5; // exponential back-off
            window.setTimeout(() => {
                // Re-open only if we haven't explicitly closed the socket by calling shutdown()
                if( this.ws )
                    this.ws = this.getWebsocket(timeout);
            },timeout);
        };
        const onData = (data:Record<string,any>) => {
            if( this.state.orderModalShown ) {
                // stop ticking while modal is open or else user will have hard time choosing the time.
            }
            else {
                const dehydrated = data as WSPayloadType.WSPayload.Dehydrated;
                if( WSPayloadType.WSPayload.isValid(dehydrated) ) {
                    const wsPayload = WSPayloadType.WSPayload.hydrate(dehydrated);
                    this.currentSeconds  = wsPayload.seconds;
                    this.startUtcSeconds = Math.max(this.startUtcSeconds||this.currentSeconds!,this.currentSeconds!);
                }
                else if( data.message ) {
                    console.log(`Got a message from server `,data);
                    if( data.donotReopen )
                        this.closeWebSocket('do not re-open');
                }
                else {
                    console.warn(`Got unrecognized WS payload: `,data);
                }
            }
        }
        return tectransit.getWebsocket('ws/Dispatcher',onClose,onData);
    }

    // Overrides from OrderMap
    getStartMoment() {
        return dayjs(this.startUtcSeconds?(this.startUtcSeconds*1000):Date.now()).tz(tectransit.agency.time_zone);
    }
    getMarkerNode(mapMarker:MapMarker) {
        const position = mapMarker.getPosition();
        return (
            <div style={{background:'white'}}>
                <PlacesAutocomplete
                    key        = {`${mapMarker.name}_autocomplete`}
                    inputProps = {{
                        value       : (position?.address||''),
                        placeholder : `start typing`,
                        title       : tectransit.getLatLngKey(position?.location!),
                        style       : {border:'none'}
                    }}
                    onSelect = {( pp:google.maps.places.PlacePrediction) => {
                        tectransit.geocoder.getPlaceidLookupPromise(pp.placeId).then( geocoderResult => {
                            this.moveMarker(mapMarker,geocoderResult.geometry.location).then( message => {
                                this.alert.set(message);
                            });
                        });
                    }}
                />
            </div>
        );
    }
    onCreateOrder() {
        return this.props.onCreateOrder(this.props.order);
    }
    componentDidMount() {
        super.componentDidMount();
        this.ws = this.getWebsocket();
        if( this.props.user.location ) {
            this.moveMarker(this.markers.pickup,new google.maps.LatLng(this.props.user.location)).then( message => {
                if( message )
                    this.alert.set(`You are outside of service area. Please choose pickup location`);
                else
                    this.forceUpdate(); // because the position has just changed
            });
        }
    }
    componentWillUnmount() {
        this.closeWebSocket('unmounting');
        return super.componentWillUnmount();
    }
}
